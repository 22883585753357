const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Reward',
    dataIndex: 'code',
  },
  {
    title: 'Title Reward',
    dataIndex: 'title',
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
  },
  {
    title: 'Kategori',
    dataIndex: 'category',
  },
  {
    title: 'Area',
    dataIndex: 'area',
  },
  {
    title: 'Start Date',
    dataIndex: 'start_redeem_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'End Date',
    dataIndex: 'end_redeem_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns
