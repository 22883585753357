export default {
    code: {
        required: true,
        message: 'Kode reward tidak boleh kosong!',
    },
    title: {
        required: true,
        message: 'Title reward tidak boleh kosong!',
    },
    image: {
        required: true,
        message: 'Gambar reward tidak boleh kosong!',
    },
    type: {
        required: true,
        message: 'Tipe reward tidak boleh kosong!',
    },
    category: {
        required: true,
        message: 'Kategori reward tidak boleh kosong!',
    },
    area: {
        required: true,
        message: 'Area reward tidak boleh kosong!',
    },
    valid_until: {
        required: true,
        message: 'Tanggal valid reward tidak boleh kosong!',
    },
    uom_id: {
      required: true,
      message: 'Uom tidak boleh kosong!',
    },
    start_redeem_date: {
        required: true,
        message: 'Tanggal mulai redeem tidak boleh kosong!',
    },
    end_redeem_date: {
        required: true,
        message: 'Tanggal berakhir redeem tidak boleh kosong!',
    },
}
